import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import QRCodeGenerator from "../components/QRCodeGenerator";
import { Avatar, Select, Space } from "antd";
import "./Database.css";
import { createTag } from "../lib/utils";
const Database: React.FC = () => {
  const navigate = useNavigate();
  const topBarRef = useRef(null);

  const [searchBy, setSearchBy] = useState("artists");
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPieceCount, setTotalPieceCount] = useState(0);

  const calculateTotalPieceCount = (data: any) => {
    return data
      .filter((item: any) => item.active === true) // Filter only active items
      .reduce((total: any, item: any) => total + (item.pieceCount || 0), 0); // Sum up pieceCount
  };

  const fetchByPieceName = async () => {
    try {
      if (searchBy === "pieces") {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/piece/search/${encodeURIComponent(
            searchValue
          )}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Search failed");
        }

        const data = await response.json();
        setSearchResults(data); // Wrap in array since it returns a single result
        setIsLoading(false);
      }
      // Add additional search types here if needed
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArtistsByName = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/user/find/artist?displayName=${encodeURIComponent(searchValue)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Search by artist name failed");
      }

      const data = await response.json();
      const pieceCount = calculateTotalPieceCount(data);
      setTotalPieceCount(pieceCount);
      const activeArtists = data.filter(
        (artist: any) => artist.active === true
      );
      setSearchResults(activeArtists);
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchArtistsWithPieceCounts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/find/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch artists");
      }

      const data = await response.json();
      const pieceCount = calculateTotalPieceCount(data);
      setTotalPieceCount(pieceCount);
      const activeArtists = data.filter(
        (artist: any) => artist.active === true
      );
      setSearchResults(activeArtists);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching artists:", error);
      setSearchResults([]);
      setIsLoading(false);
    }
  };

  const fetchAllPieces = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/piece/pieces/all`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Search failed");
      }

      const data = await response.json();
      //   Filter out the data by pieces with no artist or artist with no displayName or displayName that is kshah7133
      const filteredData = data.filter(
        (piece: any) =>
          piece.artist &&
          piece.artist.displayName &&
          piece.artist.displayName !== "kshah7133"
      );
      setSearchResults(filteredData); // Wrap in array since it returns a single result
      setIsLoading(false);
    } catch (error) {
      console.error("Search error:", error);
      setSearchResults([]);
    }
  };

  const handleSearch = async () => {
    // if (!searchValue.trim()) return;

    setIsLoading(true);
    if (searchBy === "pieces") {
      if (!searchValue.trim()) {
        await fetchAllPieces();
      } else {
        await fetchByPieceName();
      }
      setIsLoading(false);
    } else {
      // when searchBy is artists
      if (!searchValue.trim()) {
        await fetchArtistsWithPieceCounts();
      } else {
        await fetchArtistsByName();
      }
      setIsLoading(false);
    }
  };

  const inputStyle = {
    padding: "10px",
    margin: "10px 0",
    backgroundColor: "#1f2129",
    color: "white",
    border: "1px solid #444",
    borderRadius: "500px",
    width: "-webkit-fill-available",
    fontFamily: "Arial, sans-serif",
  };
  console.log("searchResults: ", searchResults);
  return (
    <>
      <div
        style={{
          backgroundColor: "#1f2129",
          color: "white",
          //   minHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "25px",
        }}
      >
        <FontAwesomeIcon
          // left align
          style={{ marginRight: "auto", marginLeft: "5px" }}
          size="xl"
          icon={faAngleLeft}
          color="white"
          onClick={() => navigate("/home")}
        />
        <div
          ref={topBarRef}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            alignSelf: "flex-end",
          }}
        ></div>
        <div
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: 900,
            lineHeight: "41.86px",
            wordWrap: "break-word",
          }}
        >
          Search Database
        </div>

        {/* Main Content */}
        <div
          style={{
            display: "flex",
            alignItems: "center", // Center items vertically
            gap: "10px", // Space between the input and select
          }}
        >
          <input
            type="tel"
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={`Search by ${searchBy}`}
            style={{ ...inputStyle, width: "60%" }} // Adjust width as needed
          />
          <Select
            className="custom-select"
            defaultValue="artists"
            style={{ width: "30%" }} // Adjust width as needed
            onChange={(value) => {
              setTotalPieceCount(0);
              setSearchBy(value);
              setSearchResults([]);
            }}
            options={[
              { value: "artists", label: "Artists" },
              { value: "pieces", label: "Pieces" },
            ]}
          />
        </div>
        <button
          onClick={handleSearch}
          disabled={isLoading}
          style={{
            justifyContent: "center",
            width: "125px",
            backgroundColor: "#7800ff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            fontWeight: "bold",
            margin: "30px auto",
            opacity: isLoading ? 0.7 : 1,
          }}
        >
          {isLoading ? "Searching..." : "Search"}
        </button>
        <h1 style={{ margin: "0 0 20px 0" }}>
          Results: {searchResults.length || "0"}
        </h1>
        {totalPieceCount > 0 && (
          <h1 style={{ margin: "0 0 20px 0" }}>
            Total Piece Count: {totalPieceCount || "0"}
          </h1>
        )}
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {searchBy === "pieces" &&
            searchResults.map((result, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#2a2d36",
                  borderRadius: "10px",
                  padding: "15px",
                  display: "flex",
                  gap: "15px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {result.piece?.photo && (
                    <>
                      <img
                        src={result.piece.photo}
                        alt={result.piece.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                      />
                      <button onClick={() => createTag(result.piece.linkId)}>
                        Generate Tag
                      </button>
                    </>
                  )}
                </div>
                <div>
                  <h3 style={{ margin: "0 0 10px 0" }}>{result.piece?.name}</h3>

                  <p style={{ margin: "0 0 5px 0" }}>
                    Price: ${result.piece?.price}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Avatar
                      src={result.artist?.profilePicture}
                      alt={result.artist?.displayName}
                    />
                  </div>
                  <p style={{ margin: "0 0 5px 0" }}>
                    Artist Name: {result.artist?.displayName}
                  </p>

                  <p style={{ margin: "0", fontSize: "0.9em", color: "#999" }}>
                    {result.piece?.description}
                  </p>
                </div>
              </div>
            ))}
          {searchBy === "artists" &&
            searchResults.map((result, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#2a2d36",
                  borderRadius: "10px",
                  padding: "15px",
                  display: "flex",
                  gap: "15px",
                }}
                onClick={() => navigate(`/artist/${result.uid}`)}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {result.profilePicture && (
                    <img
                      src={result.profilePicture}
                      alt={result.displayName}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                </div>
                <div>
                  <h3 style={{ margin: "0 0 10px 0" }}>{result.piece?.name}</h3>

                  <p style={{ margin: "0 0 5px 0" }}>
                    Number of Pieces: {result.pieceCount}
                  </p>
                  {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Avatar
                    src={result.artist?.profilePicture}
                    alt={result.artist?.displayName}
                  />
                </div> */}
                  <p style={{ margin: "0 0 5px 0" }}>
                    Artist Name: {result.displayName}
                  </p>

                  <p style={{ margin: "0", fontSize: "0.9em", color: "#999" }}>
                    {result.piece?.description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Database;
