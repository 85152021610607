import { SelectProps } from "antd";
import { Comment } from "../types/types";
import { v4 as uuidv4 } from "uuid";
import exportedFont from "../components/AnotherFontExport";
import { DMN_URL } from "./constants";
import newerDmnLogo from "../assets/DMNICON.png";
import { generateStyledQRCode } from "../pages/styledQRCode";
import artLinkLogo from "../assets/artlinkblack.png";
import { jsPDF } from "jspdf";

export function getRandomColor() {
  const contrastingColors = [
    "black",
    "blue",
    "blueviolet",
    "brown",
    "cadetblue",
    "chocolate",
    "coral",
    "cornflowerblue",
    "crimson",
    "cyan",
    "darkblue",
    "darkcyan",
    "darkgoldenrod",
    "darkgray",
    "darkgreen",
    "darkgrey",
    "darkkhaki",
    "darkmagenta",
    "darkolivegreen",
    "darkorange",
    "darkorchid",
    "darkred",
    "darksalmon",
    "darkseagreen",
    "darkslateblue",
    "darkslategray",
    "darkslategrey",
    "darkturquoise",
    "darkviolet",
    "deeppink",
    "deepskyblue",
    "dimgray",
    "dimgrey",
    "dodgerblue",
    "firebrick",
    "forestgreen",
    "fuchsia",
    "gold",
    "goldenrod",
    "gray",
    "green",
    "hotpink",
    "indianred",
    "indigo",
    "lime",
    "limegreen",
    "magenta",
    "maroon",
    "mediumaquamarine",
    "mediumblue",
    "mediumorchid",
    "mediumpurple",
    "mediumseagreen",
    "mediumslateblue",
    "mediumspringgreen",
    "mediumturquoise",
    "mediumvioletred",
    "midnightblue",
    "navy",
    "olive",
    "olivedrab",
    "orange",
    "orangered",
    "orchid",
    "palevioletred",
    "peru",
    "pink",
    "plum",
    "purple",
    "rebeccapurple",
    "red",
    "rosybrown",
    "royalblue",
    "saddlebrown",
    "salmon",
    "sandybrown",
    "seagreen",
    "sienna",
    "skyblue",
    "slateblue",
    "slategray",
    "slategrey",
    "springgreen",
    "steelblue",
    "teal",
    "tomato",
    "turquoise",
    "violet",
    "yellowgreen",
  ];

  const randomIndex = Math.floor(Math.random() * contrastingColors.length);
  return contrastingColors[randomIndex];
}

export function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${month}/${day}/${year} @ ${hours}:${minutes} ${ampm}`;
}

// write a function to check if locationHistory has more than 1 location
// if it does, show the the latest location that doesn't have an endDate
// if it doesn't, show the current location aka All Comments
export const generateLatestLocation = (history: any) => {
  if (history.length > 0) {
    const latestLocation = history[history.length - 1];
    console.log("lastElement: ", latestLocation);
    if (latestLocation.id !== null) {
      return latestLocation;
    }
  }
  return null;
};

export const generateLatestLocationName = (history: any) => {
  if (history.length > 0) {
    const latestLocation = history[history.length - 1];
    if (latestLocation.id !== null) {
      return latestLocation.location?.name;
    }
  }
  return "All Comments";
};

export const countComments = (comments: Comment[], locationId: number) => {
  console.log("comments: ", comments);
  console.log("locationId: ", locationId);
  return comments?.filter((comment) => comment.locationId === locationId)
    .length;
};

export const getUIDFromResult = (result: string) => {
  result = result.replace("?source=qr", "");
  return result.split("/").pop();
};

export const formatPriceWithCommas = (price: string) => {
  if (price == null) {
    return "";
  }

  // Convert the price to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = price.toString().split(".");

  // Add commas to the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Combine the integer and decimal parts
  return decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

export const pieceTypes = {
  oil: "Oil",
  acrylic: "Acrylic",
  photo: "Photo",
  mural: "Mural",
};

type DebouncedFunction<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => void;

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): DebouncedFunction<T> {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export const listLatestLocationForCustomerFacingPiece = (history: any) => {
  // Filter out the elements with endDate of null
  const elementsWithNullEndDate = history.filter(
    (item: any) => item.endDate === null
  );

  // Sort the filtered elements by startDate in descending order
  // @ts-ignore
  elementsWithNullEndDate.sort((a, b) => {
    const dateComparison =
      new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    if (dateComparison !== 0) {
      return dateComparison;
    } else {
      // If startDate is the same, prioritize the one with formatted_address
      if (b.location.formatted_address && !a.location.formatted_address) {
        return 1;
      } else if (
        a.location.formatted_address &&
        !b.location.formatted_address
      ) {
        return -1;
      } else {
        return 0;
      }
    }
  });

  // Return the latest element (the first one in the sorted array)
  return elementsWithNullEndDate[0];
};

export const getTotalViews = (data: any) => {
  const locationHistoryViews = data.locationHistory?.reduce(
    // @ts-ignore
    (totalViews, location) => {
      return totalViews + (location.views || 0);
    },
    0
  );

  return locationHistoryViews + (data.views || 0);
};

export const pieceOptions: SelectProps["options"] = [
  {
    label: "Oil",
    value: "Oil",
    desc: "Oil",
  },
  {
    label: "Acrylic",
    value: "Acrylic",
    desc: "Acrylic",
  },
  {
    label: "Photo",
    value: "Photo",
    desc: "Photo",
  },
  {
    label: "Mural",
    value: "Mural",
    desc: "Mural",
  },
];

export const getElementTextContent = (element: { childNodes: any }) => {
  let text = "";
  for (let node of element.childNodes) {
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent.trim();
    }
  }
  return text;
};

export const generateUrl = (baseUrl: string, linkId?: string) => {
  const uniqueId = linkId || uuidv4();
  const dmnId = `#ART-${uniqueId.substring(0, 6).toUpperCase()}`;
  const linkedUrl = `${baseUrl}/${uniqueId}?source=qr`;
  return {
    dmnShortenedId: dmnId,
    linkedUrl,
  };
};

export const getMostRecentLocation = (locationHistory: any) => {
  if (!Array.isArray(locationHistory) || locationHistory.length === 0) {
    return { name: "Unknown Location" };
  }

  const sortedHistory = [...locationHistory].sort((a, b) => {
    const dateA = new Date(a.startDate).getTime();
    const dateB = new Date(b.startDate).getTime();

    // Tie-breaking logic: prioritize "Thrive Workplace Ball Park" explicitly
    if (dateA === dateB) {
      return -1;
    }

    return dateB - dateA; // Descending sort by date
  });

  return sortedHistory[0]?.location || { name: "Unknown Location" };
};

export const groupByLocation = (pieces: any) => {
  const locationMap = new Map();

  // @ts-ignore
  pieces.forEach((piece) => {
    const mostRecentLocation = getMostRecentLocation(piece.locationHistory);
    const locationName = mostRecentLocation.name;

    if (!locationMap.has(locationName)) {
      locationMap.set(locationName, []);
    }
    locationMap.get(locationName).push(piece);
  });

  return Array.from(locationMap.entries()).map(([location, pieces]) => ({
    location,
    pieces,
  }));
};

export const generateOGMetadata = (
  artPieceInformation: any,
  artistInformation: any,
  taggedEntity: string
) => {
  const dmnId = `#DMN-${taggedEntity}`;
  const pieceName = artPieceInformation.name || "Untitled Piece";
  const itemNumber = dmnId || "Unknown Item Number";
  const artistName =
    artistInformation.displayName || artistInformation.name || "Unknown Artist";
  const pieceType = artPieceInformation.type.join(", ") || "Unknown Type";
  const size =
    `${artPieceInformation.height} x ${artPieceInformation.width} ${artPieceInformation.units}` ||
    "Unknown Size";
  const location = artPieceInformation.location?.name || "Unknown Location";
  const price = artPieceInformation.price
    ? `$${artPieceInformation.price}`
    : "Price not available";
  const logo = "https://i.imgur.com/dlJvkM6.png"; // Dark Matter Network - Logo URL
  const photo = artPieceInformation.photo || logo; // Fallback to logo if no photo

  return {
    title: `${pieceName} by ${artistName} - ArtLink`,
    description: `Item Number: ${itemNumber}, Type: ${pieceType}, Size: ${size}, Location: ${location}, Price: ${price}`,
    image: photo,
    url: `https://darkmatter.network/artpiece/${artPieceInformation.id || ""}`,
    type: "article",
    siteName: "ArtLink",
  };
};

export const createTag = async (linkId: string) => {
  const { dmnShortenedId, linkedUrl } = generateUrl(DMN_URL, linkId);
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [127, 203.2],
  });

  doc.addFileToVFS("roadgeek-2005-engschrift.ttf", exportedFont);
  doc.addFont("roadgeek-2005-engschrift.ttf", "roadgeek", "normal");
  doc.setFont("roadgeek", "normal");

  doc.setTextColor(0, 0, 0);

  doc.addImage(newerDmnLogo, "PNG", 5, 5, 40, 40); // Adds image at (10, 10) with dimensions 40x40
  doc.addImage(artLinkLogo, "PNG", 10, 49, 25.8, 6); // Adds artLink logo (Original Dimensions 734X171 - Used Dimensions 25.8X6)
  try {
    const qrCodeDataURL = await generateStyledQRCode(linkedUrl);
    // Add the QR code to the PDF at the bottom left
    doc.addImage(qrCodeDataURL, "PNG", 7, 85, 38, 38);
    // const qrCodeDataURL = await QRCode.toDataURL(linkedUrl);
    // // Add the QR code to the PDF at the bottom left
    // doc.addImage(qrCodeDataURL, "PNG", 3, 80, 45, 45);
  } catch (err) {
    console.error("Could not generate QR code", err);
  }

  const titleElement = document.getElementById("title");
  const title = titleElement ? getElementTextContent(titleElement) : "";
  const artist = document.getElementById("artist")?.innerText || "";
  const size = document.getElementById("size")?.innerText || "";
  const medium = document.getElementById("medium")?.innerText || "";
  const price = document.getElementById("price")?.innerText || "";
  // Strip out "By " from the artist name
  const artistName = artist.replace("By ", "");

  // Add other fields such as price based on their ids
  doc.setFontSize(36);
  // Position text to the right of the logo, adjust the x-coordinate
  doc.text(`Title: ${title}`, 70, 25);
  doc.text(`Artist: ${artistName}`, 70, 45);
  doc.text(`Medium: ${medium}`, 70, 65);
  doc.text(`Size: ${size}`, 70, 85);
  doc.text(`Price: ${price}`, 70, 105);
  // Add other fields such as price
  doc.setFontSize(24);

  doc.setCharSpace(-0.25);
  doc.text("Connect, engage,", 8, 65);
  doc.text("converse, support,", 8, 73);
  doc.text("purchase:", 8, 81);

  doc.text(dmnShortenedId, 150, 120);

  const blob = doc.output("blob");
  const blobURL = URL.createObjectURL(blob);

  // This is for mobile devices
  if (
    navigator &&
    navigator.share &&
    /Mobi|Android/i.test(navigator.userAgent)
  ) {
    // Use the navigator.share API for mobile devices if available
    const file = new File([blob], `${dmnShortenedId.substring(1)}.pdf`, {
      type: "application/pdf",
    });
    navigator
      .share({
        title: "Tag",
        text: "Download your tag",
        files: [file],
      })
      .catch((error) => console.error("Sharing failed:", error));
  } else {
    // create a temporary link element
    const link = document.createElement("a");

    // set attributes and href for the link. This will define the file name for the download
    link.href = blobURL;
    link.download = `${dmnShortenedId.substring(1)}.pdf`; // or any other name you wish to give

    // append the link to the body
    document.body.appendChild(link);

    // if on mobile device, use the click event to trigger the download

    // trigger download
    link.click();

    // clean up the DOM
    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL);
  }
};
